import { BackgroundComponent } from "./components/background/background.component";
import { CommonModule } from "@angular/common";
import { ContentTabsComponent } from "./components/content-tabs/content-tabs.component";
import { EdgeInformationComponent } from "./components/edge-information/edge-information.component";
import { HeaderComponent } from "./components/header/header.component";
import { HeaderMenuPopoverComponent } from "./components/header-menu-popover/header-menu-popover.component";
import { InfoCardComponent } from "./components/info-card/info-card.component";
import { IonicModule } from "@ionic/angular";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { SliderComponent } from "./components/slider/slider.component";
import { SwiperModule } from "swiper/angular";

@NgModule({
	declarations: [
		HeaderComponent,
		SliderComponent,
		ContentTabsComponent,
		InfoCardComponent,
		BackgroundComponent,
		HeaderMenuPopoverComponent,
		EdgeInformationComponent,
	],
	imports: [CommonModule, IonicModule, RouterModule, SwiperModule],
	exports: [
		HeaderComponent,
		SliderComponent,
		ContentTabsComponent,
		InfoCardComponent,
		BackgroundComponent,
		HeaderMenuPopoverComponent,
		EdgeInformationComponent,
	],
	providers: [],
})
export class ComponentsModule {}
