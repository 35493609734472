import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-background',
  templateUrl: './background.component.html',
  styleUrls: ['./background.component.scss'],
})
export class BackgroundComponent implements OnInit {

  bgImage = '../../assets/backgrounds/image_4.jpeg';

  constructor() { }

  ngOnInit() {}

}
