import {
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ViewChild,
} from "@angular/core";

import { IonSlides } from "@ionic/angular";

@Component({
	selector: "app-slider",
	templateUrl: "./slider.component.html",
	styleUrls: ["./slider.component.scss"],
})
export class SliderComponent implements OnInit {
	@Input() sliders: any;

	@Output() parentIndex = new EventEmitter<number>();

	@ViewChild("slides") slides: IonSlides;

	//curSlider = 0;
	active = 0;

	constructor() {}

	ngOnInit() {}

	next() {
		this.slides.slideNext();
	}

	prev() {
		this.slides.slidePrev();
	}

	showSlider(i) {
		this.active = i;
		this.parentIndex.emit(i);
	}
}
