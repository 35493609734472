import {Component, Input, OnInit, ViewChild} from '@angular/core';
import Swiper, { SwiperOptions } from 'swiper';

import {IonSlides} from '@ionic/angular';

@Component({
  selector: 'app-content-tabs',
  templateUrl: './content-tabs.component.html',
  styleUrls: ['./content-tabs.component.scss'],
})
export class ContentTabsComponent implements OnInit {

  @Input() tabs;

  @ViewChild('swiper') swiper: IonSlides;

  swiperConfig: SwiperOptions = {
    slidesPerView: 1
  };

  tabIndex = 0;

  constructor() { }

  ngOnInit() {}

  showTab(i) {
    this.tabIndex = i;
  }
}
