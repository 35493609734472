import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {

  contacts = [
    {
      name: 'Tennisclub Grün-Weiss Arsbeck e.V.',
      street: 'Bücherstraße',
      hNr: '45',
      pc: '41844',
      loc: 'Wegberg',
      phone: '02436 / 2442',
      mail: 'geschaeftsfuehrer@tennisclub-arsbeck.de',
      poBox: 'Postfach 1172',
      poLoc: '41837 Wegberg'
    }
  ];

  constructor(private router: Router) { }

  ngOnInit() {}

  goTo(url) {
    this.router.navigateByUrl(url);
  }
}
