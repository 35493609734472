import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class PlatformService {

  constructor(
    private platform: Platform
  ) { }

  isNativeBrowser() {
    return this.platform.is('mobileweb')
  }

  isNativeApp() {
    return !this.platform.is('desktop')
  }

  isDesktopBrowser() {
    return this.platform.is('desktop')
  }

  getWidth() {
    return this.platform.width()
  }
  
}
