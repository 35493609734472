import { AfterViewInit, Component, OnInit } from "@angular/core";

import { HeaderMenuPopoverComponent } from "../header-menu-popover/header-menu-popover.component";
import { PlatformService } from "../../services/platform/platform.service";
import { PopoverController } from "@ionic/angular";
import { Router } from "@angular/router";
import { timer } from "rxjs";

@Component({
	selector: "app-header",
	templateUrl: "./header.component.html",
	styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit, AfterViewInit {
	hideElem = false;
	backUp = false;
	mouseOnFirstToolbar = false;
	mouseOnSecondToolbar = false;
	popOpen = false;

	logo = "../../../assets/logo.png";

	public subPages = [];

	public aboutUs = [
		{ title: "CLUBANLAGE", url: "/facility" },
		{ title: "BOULE", url: "/boule" },
		{ title: "VORSTAND", url: "/directors" },
		{ title: "BEITRÄGE", url: "/dues" },
		{ title: "SPENDEN", url: "donations", dropIcon: false },
	];

	public membersArea = [
		{ title: "TRAINING", url: "/training" },
		{ title: "JUGEND BEREICH", url: "/youth-area" },
		{ title: "BILDERECKE", url: "/picture-corner" },
	];

	public formal = [
		{ title: "DATENSCHUTZ", url: "privacy", newTab: false },
		{ title: "IMPRESSUM", url: "/impressum", newTab: false },
		{ title: "SPIELORDNUNG", url: "/statutes", newTab: false },
		{ title: "AGB", url: "/gtc", newTab: false },
	];

	public pages = [
		{ title: "HOME", url: "/home", icon: "home-outline", dropIcon: false },
		{
			title: "ÜBER UNS",
			url: this.aboutUs,
			icon: "information-circle-outline",
			dropIcon: true,
		},
		{
			title: "MITGLIEDER BEREICH",
			url: this.membersArea,
			icon: "people-outline",
			dropIcon: true,
		},
		{
			title: "KONTAKT",
			url: "/contact",
			icon: "call-outline",
			dropIcon: false,
		},
		{
			title: "FORMELLES",
			url: this.formal,
			icon: "document-text-outline",
			dropIcon: true,
		},
		{ title: "DOWNLOAD", url: "/download", dropIcon: false },
	];

	constructor(
		public platformService: PlatformService,
		private router: Router,
		private popCtrl: PopoverController
	) {}

	ngOnInit() {}

	ngAfterViewInit() {}

	async presentPopover(ev: any, url) {
		if (typeof url === "string") {
			return;
		}
		this.popOpen = true;
		const pageList = this.fillArray(url);
		const popover = await this.popCtrl.create({
			component: HeaderMenuPopoverComponent,
			componentProps: { pages: pageList },
			cssClass: "menu-popover",
			event: ev,
			showBackdrop: false,
			translucent: true,
		});
		await popover.present();
	}

	toggleMenu(anim, force) {
		if (anim === true) {
			timer(200).subscribe((x) => {
				if (
					this.mouseOnFirstToolbar === false &&
					this.mouseOnSecondToolbar === false
				) {
					this.backUp = true;
					timer(500).subscribe((x) => {
						this.backUp = false;
						this.hideElem = false;
					});
				}
				if (
					this.mouseOnSecondToolbar === true ||
					(this.mouseOnFirstToolbar === true && force === true)
				) {
					this.mouseOnSecondToolbar = false;
					this.backUp = true;
					timer(500).subscribe((x) => {
						this.backUp = false;
						this.hideElem = false;
					});
				}
			});
		} else {
			this.hideElem = !this.hideElem;
		}
	}

	interactButton(url, force) {
		if (typeof url != "string") {
			this.subPages = [];
			this.fillArray(url);
			if (this.mouseOnFirstToolbar === true && this.hideElem === true) {
				return;
			}
			if (this.hideElem === false) {
				this.toggleMenu(false, force);
			}
		} else {
			if (this.hideElem === true) {
				this.toggleMenu(true, true);
			}
			if (force === true) {
				this.toggleMenu(true, force);
				this.goTo(url);
			}
		}
	}

	goTo(url) {
		this.router.navigateByUrl(url);
	}

	fillArray(array) {
		this.subPages = [];
		for (let i = 0; i < array.length; i++) {
			this.subPages.push(array[i]);
		}
		return this.subPages;
	}
}
