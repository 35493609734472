import {Component, Input, OnInit} from '@angular/core';

import {AppComponent} from '../../../app.component';
import {Platform} from "@ionic/angular";
import {Router} from '@angular/router';

@Component({
  selector: 'app-info-card',
  templateUrl: './info-card.component.html',
  styleUrls: ['./info-card.component.scss'],
})
export class InfoCardComponent implements OnInit {

  @Input() data;

  constructor(private app: AppComponent, private router: Router, public platform: Platform) { }

  ngOnInit() {}

  openPage(url) {
    this.router.navigateByUrl(url);
  }

  smallLeft(i) {
    if (i % 3 === 0 && this.app.isDesktop() && this.platform.width() < 1220) return true;
  }

  largeLeft(i) {
    if (i % 2 === 0 && this.app.isDesktop() && this.platform.width() > 1220 && i !== this.data.length - 1) return true;
  }

  largeRight(i) {
    if ((i + 1) % 2 === 0 && this.app.isDesktop() && this.platform.width() > 1220) return true;
  }

  smallRight(i: number) {
    if ((i + 1) % 3 === 0 && this.app.isDesktop() && this.platform.width() < 1220) return true;
  }
}
