import { MenuController, Platform } from "@ionic/angular";

import { Component } from "@angular/core";
import { Router } from "@angular/router";

@Component({
	selector: "app-root",
	templateUrl: "app.component.html",
	styleUrls: ["app.component.scss"],
})
export class AppComponent {
	hideElem = false;
	currentTab;

	public subPages = [];

	public aboutUs = [
		{ title: "CLUBANLAGE", url: "/facility" },
		{ title: "BOULE", url: "/boule" },
		{ title: "VORSTAND", url: "/directors" },
		{ title: "BEITRÄGE", url: "/dues" },
		{ title: "SPENDEN", url: "donations", dropIcon: false },
	];

	public membersArea = [
		{ title: "TRAINING", url: "/training" },
		{ title: "JUGEND BEREICH", url: "/youth-area" },
		{ title: "BILDERECKE", url: "/picture-corner" },
	];

	public formal = [
		{ title: "DATENSCHUTZ", url: "/privacy" },
		{ title: "IMPRESSUM", url: "/impressum" },
		{ title: "SPIELORDNUNG", url: "/statutes" },
		{ title: "AGB", url: "/gtc" },
	];

	public pages = [
		{ title: "HOME", url: "/home", icon: "home-outline", dropIcon: false },
		{
			title: "ÜBER UNS",
			url: this.aboutUs,
			icon: "information-circle-outline",
			dropIcon: true,
		},
		{
			title: "MITGLIEDER BEREICH",
			url: this.membersArea,
			icon: "people-outline",
			dropIcon: true,
		},
		{
			title: "KONTAKT",
			url: "/contact",
			icon: "call-outline",
			dropIcon: false,
		},
		{
			title: "FORMELLES",
			url: this.formal,
			icon: "document-text-outline",
			dropIcon: true,
		},
		{ title: "DOWNLOAD", url: "/download", dropIcon: false },
	];

	constructor(
		private router: Router,
		public menuCtrl: MenuController,
		private platform: Platform
	) {}

	closeMenu(url, drop) {
		if (!drop) {
			this.menuCtrl.close();
		}
		this.goTo(url, undefined, undefined);
	}

	goTo(url, title, ev) {
		if (typeof url != "string") {
			this.subPages = [];
			this.fillArray(url);
			if (this.currentTab === title) {
				this.hideElem = !this.hideElem;
				return;
			}
			if (!this.hideElem) {
				this.hideElem = !this.hideElem;
			}
		} else {
			this.router.navigateByUrl(url);
			this.menuCtrl.close();
		}
		this.currentTab = ev.currentTarget.id;
	}

	fillArray(array) {
		this.subPages = [];
		for (let i = 0; i < array.length; i++) {
			this.subPages.push(array[i]);
		}
	}

	isNativeApp() {
		if (this.platform.is("ios") || this.platform.is("android")) {
			if (!this.platform.is("mobileweb") && !this.platform.is("desktop")) {
				return true;
			}
		}
	}

	isNativeWeb() {
		if (this.platform.is("mobileweb")) {
			return true;
		}
	}

	isDesktop() {
		if (this.platform.is("desktop")) {
			return true;
		}
	}
}
