import { Component, OnInit } from "@angular/core";

import { EdgeInformationComponent } from "../../shared/components/edge-information/edge-information.component";
import { ModalController } from "@ionic/angular";

@Component({
	selector: "app-home",
	templateUrl: "./home.page.html",
	styleUrls: ["./home.page.scss"],
})
export class HomePage implements OnInit {
	edgeShown = false;

	data = [
		{
			title: "Mitglied werden",
			subtitle: "Schnupperaktion für neue Mitglieder",
			text: "Werden Sie Mitglied in unserem Verein. Sie zahlen im ersten Kalenderjahr der Mitgliedschaft nur den halben regulären Mitgliedsbeitrag",
			toPage: "dues",
		},
		{
			title: "Boule",
			text: "Nach oder auch während der aktiven Tenniskarriere weiter sportlich aktiv bleiben?\nUnsere Boulebahn macht`s möglich auch außerhalb der regulären Tennissaison",
			imageURL: "../../assets/home/boule.jpg",
			toPage: "boule",
		},
		{
			title: "Werbung / Sponsoring",
			text: "Sie haben interesse an Werbung in unserem Club und würden uns damit gerne unterstützen?\nWir informieren Sie gerne in einem persönlichen Gespräch",
			imageURL: "../../assets/home/Sponsors.png",
			toPage: "donations",
		},
	];

	constructor(
		public edge: EdgeInformationComponent,
		private modalController: ModalController
	) {}

	ngOnInit() {}

	toggleEdge(data) {
		this.edgeShown = data;
	}

	async openEdgeModal() {
		const modal = await this.modalController.create({
			component: EdgeInformationComponent,
			cssClass: "edge-information",
		});
		modal.present();
	}
}
