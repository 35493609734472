import {Component, Input, OnInit} from '@angular/core';
import {NavController, PopoverController} from "@ionic/angular";

@Component({
  selector: 'app-header-menu-popover',
  templateUrl: './header-menu-popover.component.html',
  styleUrls: ['./header-menu-popover.component.scss'],
})
export class HeaderMenuPopoverComponent implements OnInit {

  @Input() pages: any;

  constructor(private popCtrl: PopoverController, private navCtrl: NavController) { }

  ngOnInit() {}

  goTo(url) {
    this.navCtrl.navigateForward(url)
    this.dismiss()
  }

  dismiss() {
    this.popCtrl.dismiss();
  }
}
