import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import {IonicModule, IonicRouteStrategy} from '@ionic/angular';

import { HomePageRoutingModule } from './home-routing.module';

import { HomePage } from './home.page';
import {ComponentsModule} from '../../shared/components.module';
import {FooterComponent} from "../../shared/components/footer/footer.component";
import {RouteReuseStrategy} from "@angular/router";
import {EdgeInformationComponent} from "../../shared/components/edge-information/edge-information.component";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        HomePageRoutingModule,
        ComponentsModule
    ],
    exports: [
        FooterComponent
    ],
    declarations: [HomePage, FooterComponent],
    providers: [EdgeInformationComponent],
})
export class HomePageModule {}
