import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { NgModule } from '@angular/core';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./modules/home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'contact',
    loadChildren: () => import('./modules/contact/contact.module').then(m => m.ContactPageModule)
  },
  {
    path: 'facility',
    loadChildren: () => import('./modules/about-us/facility/facility.module').then(m => m.FacilityPageModule)
  },
  {
    path: 'directors',
    loadChildren: () => import('./modules/about-us/directors/directors.module').then(m => m.DirectorsPageModule)
  },
  {
    path: 'picture-corner',
    loadChildren: () => import('./modules/members-area/picture-corner/picture-corner.module').then(m => m.PictureCornerPageModule)
  },
  {
    path: 'dues',
    loadChildren: () => import('./modules/about-us/dues/dues.module').then(m => m.DuesPageModule)
  },
  {
    path: 'training',
    loadChildren: () => import('./modules/members-area/training/training.module').then(m => m.TrainingPageModule)
  },
  {
    path: 'youth-area',
    loadChildren: () => import('./modules/members-area/youth-area/youth-area.module').then(m => m.YouthAreaPageModule)
  },
  {
    path: 'privacy',
    loadChildren: () => import('./modules/formal/privacy/privacy.module').then(m => m.PrivacyPageModule)
  },
  {
    path: 'impressum',
    loadChildren: () => import('./modules/formal/impressum/impressum.module').then(m => m.ImpressumPageModule)
  },
  {
    path: 'statutes',
    loadChildren: () => import('./modules/formal/statutes/statutes.module').then(m => m.StatutesPageModule)
  },
  {
    path: 'gtc',
    loadChildren: () => import('./modules/formal/gtc/gtc.module').then(m => m.GtcPageModule)
  },
  {
    path: 'donations',
    loadChildren: () => import('./modules/about-us/donations/donations.module').then(m => m.DonationsPageModule)
  },
  {
    path: 'boule',
    loadChildren: () => import('./modules/about-us/boule/boule.module').then(m => m.BoulePageModule)
  },
  {
    path: 'download',
    loadChildren: () => import('./modules/download/download.module').then(m => m.DownloadPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, useHash: true })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
