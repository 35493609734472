import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ModalController, Platform } from "@ionic/angular";
import { ImagePreviewComponent } from "../image-preview/image-preview.component";

@Component({
	selector: "app-edge-information",
	templateUrl: "./edge-information.component.html",
	styleUrls: ["./edge-information.component.scss"],
})
export class EdgeInformationComponent implements OnInit {
	@Input() isShown = false;
	@Output() showEdge = new EventEmitter<boolean>();

	constructor(private modalCtrl: ModalController, private platform: Platform) {}

	ngOnInit() {}

	isNative() {
		return this.platform.is("ios") || this.platform.is("android");
	}

	dismiss() {
		this.modalCtrl.dismiss();
	}
}
